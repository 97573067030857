import { useLocation } from "react-router-dom";

import { useNetworkCtx } from "./Context/networkCtx";
import { useOrgCtx } from "./Context/orgCtx";

export type Dashboard = "services" | "mpn" | "admin" | "mec";

interface CurrentDashboardState {
  isServices: boolean;
  isMPN: boolean;
  isAdminDashboard: boolean;
  isMEC: boolean;
  dashboard: Dashboard;
}

export function useCurrentDashboard(): CurrentDashboardState {
  const { pathname } = useLocation();

  const isMPN = pathname.split("/")[3] === "mpn";
  const isMEC = pathname.split("/")[3] === "mec";
  const isAdminDashboard = pathname.split("/").includes("admin");

  const noAccess =
    pathname.split("/")[2]?.includes("no-dashboard") ||
    pathname.split("/")[2]?.includes("no-org") ||
    pathname.includes("app/user/settings");

  const isServices = !isMPN && !isAdminDashboard && !isMEC && !noAccess;

  return {
    isServices,
    isMPN,
    isMEC,
    isAdminDashboard,
    dashboard: isServices
      ? "services"
      : isMPN
      ? "mpn"
      : isAdminDashboard
      ? "admin"
      : isMEC
      ? "mec"
      : null
  };
}

export function useGetDashboardBaseUrl() {
  const { dashboard } = useCurrentDashboard();

  const orgCtx = useOrgCtx();
  const networkCtx = useNetworkCtx();

  switch (dashboard) {
    case "services":
      return `/app/${orgCtx}/services/`;
    case "mpn":
      const mpnSubpath = networkCtx || "network";
      return `/app/${orgCtx}/mpn/${mpnSubpath}/`;
    case "mec":
      return `/app/${orgCtx}/mec/`;
    case "admin":
      return `/app/${orgCtx}/admin/`;
    default:
      return "";
  }
}
