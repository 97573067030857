import styled from "styled-components";

export const Tag = styled.span`
  display: inline-block;
  line-height: 20px;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 0 16px;
  border-radius: 1rem;
  white-space: nowrap;
`;

export const InputTagMarker = styled.span`
  display: flex;
  background: ${({ theme }) => theme.grey};
  border-radius: 1rem;
  gap: 4px;
  padding: 4px 12px;
  & button {
    font-weight: 600;
    font-size: 12px;
    padding: 0;
    &:hover {
      color: ${({ theme }) => theme.red};
    }
  }
`;

export const TableTagsWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

export const TableTag = styled.span`
  display: inline-block;
  border-radius: 2px;
  font-size: 10px;
  border: 1px solid #dddddd;
  background-color: white;
  padding: 4px 6px;
  white-space: nowrap;
`;

export const ReadOnly = styled.span`
  background-color: ${({ theme }) => theme.backgroundLight};
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: 5px;
  letter-spacing: 0.5px;
  font-size: 9px;
  margin-right: auto;
  align-self: center;
`;
