import { useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

import DownArrow from "@img/arrow_light_down.svg";
import UpArrow from "@img/arrow_light_up.svg";
import User from "@img/user.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { TextButton } from "@src/Components/Buttons/Text";
import { useToggle } from "@src/Hooks/toggle";
import { useCurrentDashboard, useGetDashboardBaseUrl } from "@src/Hooks/useCurrentDashboard";

import { useLogoutHandler } from "./Auth/Logout";
import { DashboardsMenu } from "./DashboardsMenu";
import { HeaderOrgSelector } from "./Header/HeaderOrgSelector";
import { HeaderItem, HeaderLabel, MenuLink, Separator, UserMenu } from "./Header/HeaderStyles";
import { NetworkSelector } from "./MPNDashboard/NetworkSelector";
import { ServiceDashboardHeaderItems } from "./ServicesDashboard/ServiceDashboardHeaderItems";
import { useSession } from "./Session";
import { ClientLogo } from "./UserSettings/ClientLogo";

const headerPadding = 32;

export const HeaderWrapper = styled.header`
  grid-area: header;
  height: 50px;
  background: white;
  display: flex;
  gap: 10px;
  align-items: stretch;
  justify-content: flex-end;
  padding: 0 ${headerPadding}px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
`;

export const SeparatorUser = styled(Separator)`
  &:first-of-type {
    margin-top: auto;
  }
`;

export const MenuButton = styled(TextButton)`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #666666;
`;

export function Header() {
  const { state: isOpenUser, toggle: toggleMenuUser, off: closeMenuUser } = useToggle();

  const { session } = useSession();
  const userName = session?.identity?.traits?.name;

  const logout = useLogoutHandler();

  const userMenu = useRef();
  useOnClickOutside(userMenu, closeMenuUser);

  const { isServices, isMPN, isAdminDashboard, isMEC } = useCurrentDashboard();
  const baseDashboardUrl = useGetDashboardBaseUrl();

  return (
    <HeaderWrapper>
      {(isServices || isMPN || isAdminDashboard || isMEC) && <HeaderOrgSelector />}

      {isMPN && <NetworkSelector />}

      <ClientLogo />

      {isServices && <ServiceDashboardHeaderItems />}

      <DashboardsMenu />

      <HeaderItem ref={userMenu}>
        <Clickable onClick={toggleMenuUser}>
          <User height={30} width={30} />
          <HeaderLabel>{userName}</HeaderLabel>
          {isOpenUser ? <UpArrow height={14} width={14} /> : <DownArrow height={14} width={14} />}
        </Clickable>
        {isOpenUser ? (
          <UserMenu>
            <User height={160} width={160} />
            <SeparatorUser />
            <MenuLink to={baseDashboardUrl + "user/settings"}>Profile settings</MenuLink>
            <SeparatorUser />
            <MenuButton onClick={logout}>Sign out</MenuButton>
          </UserMenu>
        ) : null}
      </HeaderItem>
    </HeaderWrapper>
  );
}
